import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    MenuItem,
    TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useFetch, useSnackbar } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { CountryFlag } from "../../components/CountryFlag";
import { INPUT_VALIDATION } from "../../constants";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import { Stack } from "@mui/system";
import { iso31661 } from "iso-3166";
import { useMemo } from "react";

SiteCreateForm.propTypes = {
    closeModal: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
};

export function SiteCreateForm({ contractUuid, closeModal, mutate, users }) {
    const { openSnackbar } = useSnackbar();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            name: "",
            supervisorUuid: "",
            countryCode: "",
        },
    });

    const countriesArray = useMemo(
        () =>
            iso31661.sort((countryA, countryB) =>
                countryA.name > countryB.name ? 1 : -1
            ),
        []
    );

    const { post } = useFetch();

    const onSubmit = async (payload) => {
        const createdSite = await post(BACKEND_ROUTES.SITE, {
            body: { ...payload, contractUuid },
        });

        if (createdSite) {
            mutate();
            closeModal();
            openSnackbar(
                `Site ${createdSite.name} created successfully.`,
                "success"
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Create a new site</DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Stack spacing={3}>
                    <TextField
                        id="name"
                        fullWidth
                        type="text"
                        label="Name *"
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                        {...register("name", {
                            required: INPUT_VALIDATION.REQUIRED,
                        })}
                    />
                    <Controller
                        control={control}
                        name="supervisorUuid"
                        render={({ field: { ref, ...fieldProps } }) => (
                            <TextField
                                {...fieldProps}
                                fullWidth
                                id="supervisorUuid"
                                select
                                label="Supervisor *"
                                inputRef={ref}
                                error={Boolean(errors.supervisorUuid)}
                                helperText={errors.supervisorUuid?.message}
                            >
                                {users.map((user) => (
                                    <MenuItem key={user.uuid} value={user.uuid}>
                                        {`${user.firstName} ${user.lastName}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        rules={{
                            required: INPUT_VALIDATION.REQUIRED,
                        }}
                    />
                    <Controller
                        control={control}
                        name="countryCode"
                        render={({ field: { ref, ...fieldProps } }) => (
                            <TextField
                                {...fieldProps}
                                fullWidth
                                id="countryCode"
                                select
                                label="Country *"
                                inputRef={ref}
                                error={Boolean(errors.countryCode)}
                                helperText={errors.countryCode?.message}
                            >
                                {countriesArray.map((country) => (
                                    <MenuItem
                                        key={country.alpha3}
                                        value={country.alpha3}
                                    >
                                        <Stack direction="row" spacing={1}>
                                            <CountryFlag country={country} />
                                            <span>{country.name}</span>
                                        </Stack>
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        rules={{
                            required: INPUT_VALIDATION.REQUIRED,
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={closeModal}>
                    Cancel
                </Button>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Create
                </LoadingButton>
            </DialogActions>
        </form>
    );
}
